import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import ScrollOut from "scroll-out"

import ArticleHeaderGrid from "../../../components/athena/articleHeaderGrid"
import ContributorsGrid from "../../../components/athena/contributorsGrid"
import MoreArticlesGrid from "../../../components/athena/moreArticlesGrid"
import AthenaCta from "../../../components/athena/athenaCta"
import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import GridCollagePhoto from "../../../components/athena/gridCollagePhoto"
import { getMaxSectionImageWidth } from "./cycle3-shared"

import styles from "./new-impact-models.module.scss"
import sharedStyles from "./cycle3-shared.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
  headerPosition: styles.position,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const athenaCta = article.athenaCta

  let maxSectionImageWidth = getMaxSectionImageWidth()

  // Images
  const {
    headerImage,
    section1Image,
    section2Image,
    section3Image,
    section4Image,
  } = data.craft

  // Initialize up ScrollOut library
  useEffect(() => {
    let so = ScrollOut({
      threshold: 0.1,
      cssProps: {
        viewportY: true,
      },
    })

    return so.teardown
  }, [])

  return (
    <Layout seo={seo} mode="athena" theme={theme} shyHeader={false}>
      <Helmet>
        <body className={`athena ${styles.body}`} />
      </Helmet>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <div className={styles.articleBody}>
            <div className={styles.text}>
              <div className={`${styles.narrow} wrapper`}>
                <div
                  className={`${styles.headerContentBlock} columns is-tablet is-centered`}
                >
                  <ArticleHeaderGrid
                    titleColor="#E9F7ED"
                    issue={article.issue}
                    title={article.title}
                    subtitle={article.articleSubTitle}
                    headerImage={headerImage}
                  />
                </div>
                <div
                  className={`${styles.contentBlock} columns is-tablet is-centered`}
                >
                  <div className="column is-10-tablet">
                    <div className="mb-8 user-content-athena">
                      <p>
                        A decade ago, our focus at Zeus Jones was to transform
                        traditional, communications-centric brands into modern,
                        purpose-driven brands. At the time, this change felt
                        monumental, and in many cases it was. Brands began to
                        speak with intention, give voice to new ideas, and
                        create stronger connections between business goals and
                        philanthropic efforts. Brands began to stand for
                        something.
                      </p>
                      <p>Now it&rsquo;s time to go further. </p>
                      <p>
                        Over the past several years, we&rsquo;ve shifted our
                        efforts to evolve purpose-driven brands into
                        impact-driven businesses. This evolution is both natural
                        and necessary. As brands began to lead with purpose and
                        transcend the transactional, they connected with
                        consumers in more meaningful ways. Subsequently,
                        consumers today expect more from brands they support. It
                        isn&rsquo;t enough to simply stand for something.
                        It&rsquo;s time to do something.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#665A62" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section1Image.url}
                    width={section1Image.width}
                    height={section1Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={8}
                    rows={10}
                    altText={section1Image.altText}
                    gridLineColor={styles.colorPink}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#F0D3C9" }}
                >
                  Shifting to a Comprehensive Profit Model
                </h2>
                <div className="user-content-athena">
                  <p>
                    We need to change the way total profit is calculated. A new
                    model must include a deeper understanding of economic,
                    social, and environmental costs (hard and soft) that
                    companies incur while doing business. In short, we need to
                    consider profit in cooperation with society and the planet.
                    If a company creates billions of dollars in negative
                    environmental impact, that should be taken into account when
                    assessing financial performance.
                  </p>
                  <p>
                    This idea of impact-weighted profit isn&rsquo;t new, and
                    there are multiple ways to go about it. Harvard published an{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://constellation.com.br/wp-content/uploads/2020/09/A-Framework-for-Product-Impact-Weighted-Accounts.pdf"
                    >
                      impact-weighted account initiative
                    </a>{" "}
                    that measured the environmental impact of 2,500 large
                    companies based on public information. This is a solid
                    start, but to achieve greater accountability, there needs to
                    be increased business transparency to more fully understand
                    social and environmental impact.
                  </p>

                  <p>
                    Companies like Volvo have increasingly accounted for{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.volvogroup.com/en/investors/reports-and-presentations/sustainability-reports.html"
                    >
                      social and environmental impact
                    </a>
                    , and investors have taken notice. The stock has grown
                    steadily over the past five years and recent headlines like
                    "Buy 'Unicorn' Volvo Stock as the EV Market Grows" show a
                    long-term trust in transparency. Consumers want well-made
                    products, and they want to understand the larger impact of
                    how they're made.
                  </p>
                  <p>
                    In addition to incentivizing cleaner business practices,
                    this shift will also produce increased long-term profits.
                    ESG portfolios outperform standard ones because investors
                    flock to companies that have positive impact-weighted
                    profits for sustainable, long-term bets. However, the
                    systems aren&rsquo;t currently in place to make this form of
                    accounting—which requires high levels of transparency,
                    tracking, and alignment—a more wide-spread reality. This
                    challenge presents substantial opportunities to develop
                    technology that can more effectively measure impact-weighted
                    profit. An entire market could be born out of the start-ups,
                    services, and products that would support organizations in
                    accomplishing their goals and putting their purpose to work.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#3A4461" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section2Image.url}
                    width={section2Image.width}
                    height={section2Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={6}
                    rows={10}
                    altText={section2Image.altText}
                    gridLineColor={styles.colorPurple}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#F7D2E5" }}
                >
                  Shifting to cooperative ecosystems
                </h2>
                <div className="user-content-athena">
                  <p>
                    Partnerships between brands have traditionally been more
                    hype than substance. Logos sit next to each other and brand
                    purposes might overlap, but the union is typically
                    short-lived and limited in scope. Combining two consumer
                    bases to drive increased sales doesn&rsquo;t move the needle
                    on a societal level, and it&rsquo;s ultimately constricting
                    for the companies involved. But it&rsquo;s a
                    well-established model, and where there&rsquo;s comfort,
                    there&rsquo;s repetition.
                  </p>
                  <p>
                    We need to be more ambitious. Beyond the confines of
                    transactional brand partnerships lies the vast potential of
                    large-scale cooperative ecosystems. Daring to think bigger
                    and more cooperatively, these ecosystems would offer
                    businesses and consumers greater freedoms to experiment,
                    collaborate, and respond more quickly to changes in the
                    world around us.
                  </p>
                  <p>
                    A recent partnership between Subaru and Toyota, and the
                    time-tested cooperative Land O'Lakes are two examples of the
                    potential of cooperative brand ecosystems.
                  </p>
                  <p>
                    To keep pace in the rapidly growing electric vehicle market,
                    Toyota and Subaru joined forces to co-develop a{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.caranddriver.com/news/a27785342/toyota-subaru-ev-platform-electric-suv/"
                    >
                      new electric platform
                    </a>{" "}
                    that will be utilized in vehicles from both companies.
                    Rather than clamoring to get ahead in an emerging market,
                    partnership treats the realm of electric vehicles as an
                    ecosystem where brands can grow through interaction and
                    exchange of ideas. Value comes from contributions to the
                    ecosystem at large. In this instance, both companies
                    recognize that a rising electric tide lifts all vehicles,
                    and the growth of EV is ultimately in the best interest of
                    both organizations, as well as society at large.
                  </p>

                  <p>
                    Land O'Lakes reminds us that there's nothing new age about
                    cooperation. The century-old agricultural company operates
                    with a cooperative business model that's made up of a
                    complex ecosystem of businesses. When pandemic caused food
                    shortages, the resilience of the ecosystem of thousands of
                    member-owners was on display. The interconnected supply
                    chain allowed for agricultural products to keep flowing and
                    more effectively get to consumers that needed them. Contrast
                    that with companies that were at a complete standstill due
                    to COVID outbreaks in processing facilities. When some Land
                    O'Lakes members paused production, others were able to fill
                    in.
                  </p>
                  <p>
                    Building cooperation into the mindset and muscles of more
                    businesses will allow for increased impact through combined
                    expertise and experience. Talent will flow across industries
                    to drive innovation. Increased experimentation and more
                    fluid outcomes will generate a greater accumulated impact.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#526B80" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section3Image.url}
                    width={section3Image.width}
                    height={section3Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={8}
                    rows={6}
                    altText={section3Image.altText}
                    gridLineColor={styles.colorSand}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#FAEDD2" }}
                >
                  Shifting to tax accountability
                </h2>
                <div className="user-content-athena">
                  <p>Taxation is a form of cooperation. </p>
                  <p>
                    Communities provide the talent and resources that businesses
                    need to grow, and in return, businesses pay taxes to support
                    the community. Or at least they&rsquo;re supposed to.
                  </p>
                  <p>
                    Large companies dodging federal and state taxes isn&rsquo;t
                    exactly a well-kept secret. It&rsquo;s normalized. We pay
                    our taxes as individuals and expect other people to do the
                    same, but corporations, some of which receive
                    taxpayer-funded subsidies, are given a pass when it comes to
                    paying their fair share.
                  </p>
                  <p>
                    The federal corporate tax rate was lowered to 21% in 2017,
                    but many companies still pay far below that through the use
                    of stock options and tax credits to reduce taxable income.
                  </p>
                  <p>It&rsquo;s not illegal, so what&rsquo;s the problem?</p>
                  <p>
                    Selfishness is short-sighted. It always has been and always
                    will be. While executives walk away with massive bonuses,
                    communities are left with underfunded schools,
                    infrastructure, and social services.
                  </p>
                  <p>
                    The Bay Area and Seattle have experienced a staggering
                    influx of capital over the past decade with the meteoric
                    growth of the tech industry. As the economy boomed,
                    increased wealth drove up living costs and created two of
                    the most severe housing crises in the country. However,
                    efforts to raise taxes to fund affordable housing have been
                    repeatedly{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.theatlantic.com/technology/archive/2018/06/how-amazon-helped-kill-a-seattle-tax-on-business/562736/"
                    >
                      squashed
                    </a>{" "}
                    by businesses with effective tax rates that are already far
                    below state and federal minimums. An equitable and
                    sustainable society doesn't need corporate philanthropy. It
                    needs tax accountability to adequately fund social programs.
                  </p>
                  <p>
                    Current corporate tax practices are a race to the bottom.
                    The success of business enriches some and leads to the
                    suffering of others. We don't have to live this way.
                    Economic growth must provide benefits for more of society.
                    Businesses and the communities they operate in can exist
                    symbiotically, but it requires a balanced exchange. And that
                    starts with paying a fair share of taxes.
                  </p>
                  <hr />
                  <p>
                    Finding purpose elevated the potential of brands. Channeling
                    that purpose into impact will elevate people, planet, and
                    business. This is our opportunity to create the future we
                    want to live in.
                  </p>
                </div>
              </div>
            </div>

            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#42303D" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section4Image.url}
                    width={section4Image.width}
                    height={section4Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={8}
                    rows={6}
                    altText={section4Image.altText}
                    gridLineColor={styles.colorBlue}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#EBCEE1" }}
                >
                  Read more:
                </h2>
                <div className="user-content-athena">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://constellation.com.br/wp-content/uploads/2020/09/A-Framework-for-Product-Impact-Weighted-Accounts.pdf"
                      >
                        HBR: A Framework for Product Impact-Weighted Accounts
                      </a>{" "}
                      by George Serafeim and Katie Trinh
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://itep.org/55-profitable-corporations-zero-corporate-tax/"
                      >
                        55 companies that made profits but paid no corporate
                        taxes
                      </a>{" "}
                      - Institute on taxation and economic policy
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.bloomberg.com/news/articles/2020-12-01/how-wrong-was-milton-friedman-harvard-team-quantifies-the-ways"
                      >
                        How Wrong Was Milton Friedman? Harvard Team Quantifies
                        the Ways
                      </a>{" "}
                      - BLOOMBERG
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.forbes.com/sites/tommybeer/2021/04/02/more-than-50-major-us-corporations-including-nike-and-fedex-paid-no-federal-taxes-last-year/?sh=191d77d421d3"
                      >
                        More Than 50 Major U.S. Corporations—Including Nike And
                        FedEx—Paid No Federal Taxes Last Year
                      </a>{" "}
                      - FORBES
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.blackrock.com/corporate/investor-relations/larry-fink-ceo-letter"
                      >
                        BLACKROCK
                      </a>{" "}
                      - Larry Fink's Letter to CEOS
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ContributorsGrid
              zeus={article.zeusContributors}
              other={article.otherContributorsGrid}
            />
            <MoreArticlesGrid id={article.id} issue={article.issue[0].id} />
            <AthenaCta data={athenaCta} />
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
            ... on Craft_people_person_Entry {
              portrait {
                url
                width
                height
                focalPoint
                ... on Craft_people_Asset {
                  altText
                }
              }
            }
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          otherContributorsGrid {
            ... on Craft_otherContributorsGrid_BlockType {
              contributorName
              contributorWebsite
              contributorPhoto {
                url
                width
                height
                focalPoint
                ... on Craft_athenaContent_Asset {
                  altText
                }
              }
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      headerImage: asset(id: 24116) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section1Image: asset(id: 24117) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section2Image: asset(id: 24118) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section3Image: asset(id: 24119) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section4Image: asset(id: 24121) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
    }
  }
`
